<template>
	<div v-show="step !== -1" id="tour-overlay-transparent" @click.self="nextStep()"></div>
	<div v-show="step !== -1" id="tour-overlay" :style="{ clipPath: overlayClipPath }"></div>
	<transition name="fade-in">
		<div v-show="step !== -1 && showTourContent" id="tour-content" :class="`${arrowPlacement}-arrow`" :style="tourContentPosition">
			<p v-if="steps[step]?.text">
				{{ $t(steps[step]?.text) }}
			</p>
			<div class="d-flex justify-content-end">
				<button v-if="steps[step + 1]" class="btn btn-primary btn-sm me-1" @click="nextStep()" >
					{{ $t('guidedTour.nextBtn') }}
				</button>
				<button v-if="steps[step + 1]" class="btn btn-light btn-sm" @click="stopTour()">
					{{ $t('guidedTour.skipBtn') }}
				</button>
				<button v-else class="btn btn-primary btn-sm" @click="nextStep()">
					{{ $t('guidedTour.doneBtn') }}
				</button>
			</div>
		</div>
	</transition>
</template>

<script lang="ts" setup>
import { nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

const i18n = useI18n()

const step = ref(-1)

const arrowPlacement = ref('top-left')
const overlayClipPath = ref('polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)')
const tourContentPosition = reactive({
	top: '',
	left: '',
	bottom: '',
	right: '',
})
const showTourContent = ref(false)
const router = useRouter()

const emit = defineEmits(['done'])

const steps = [
	{
		element: '#sidebar-nav .sidebar-top',
		text: 'guidedTour.steps.sidebarTop',
	},
	{
		element: '#sidebar-nav .sidebar-bottom',
		text: 'guidedTour.steps.sidebarBottom',
	},
	{
		element: '.user-nav-item .dropdown-menu',
		text: 'guidedTour.steps.profileBtn',
		before: async () => {
			document.querySelector<HTMLElement>('.user-nav-item .dropdown-menu')?.classList.add('show')
			await new Promise(resolve => setTimeout(resolve, 250))
		},
		leave: async () => {
			document.querySelector<HTMLElement>('.user-nav-item .dropdown-menu')?.classList.remove('show')
			await new Promise(resolve => setTimeout(resolve, 250))
		},
	},
	{
		route: '/auth/profile',
		element: '.settings-section',
		text: 'guidedTour.steps.profileSettings',
		leave: async () => {
			await router.push('/')
		},
	},
	{
		element: '.tickets-nav-item',
		text: 'guidedTour.steps.tickets',
	},
	{
		element: 'ul.collapse-btn',
		text: 'guidedTour.steps.collapseSidebar',
		leave: async () => {
			document.querySelector<HTMLElement>('.collapse-sidebar-btn')?.click()
			await new Promise(resolve => setTimeout(resolve, 1000))
			// setTimeout(() => updateClipPath(), 1000)
		},
	},
	{
		element: 'ul.collapse-btn',
		text: 'guidedTour.steps.showSidebar',
		click: '.show-sidebar-btn',
		leave: async () => {
			document.querySelector<HTMLElement>('.show-sidebar-btn')?.click()
			overlayClipPath.value = 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)'
			await new Promise(resolve => setTimeout(resolve, 1000))
		},
	},
]

const updateClipPath = () => {
	const element: HTMLElement|null = document.querySelector(steps[step.value].element)

	if (!element) {
		overlayClipPath.value = 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)'
		return
	}

	const top = element.getBoundingClientRect().top - 8
	const bottom = element.getBoundingClientRect().top + element.getBoundingClientRect().height + 8
	const left = element.getBoundingClientRect().left - 8
	const right = element.getBoundingClientRect().left + element.getBoundingClientRect().width + 8

	overlayClipPath.value = `polygon(0% 0%, 0% 100%, ${left}px 100%, ${left}px ${top}px, ${right}px ${top}px, ${right}px ${bottom}px, ${left}px ${bottom}px, ${left}px 100%, 100% 100%, 100% 0%)`
}

const nextStep = async () => {
	showTourContent.value = false
	await new Promise(resolve => setTimeout(resolve, 500))
	step.value += 1
}

const stopTour = async () => {
	showTourContent.value = false
	overlayClipPath.value = 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)'
	await new Promise(resolve => setTimeout(resolve, 1000))
	step.value = -1
	emit('done')
}

watch(() => step.value, async (to, from) => {
	showTourContent.value = false

	if (steps[from]?.leave) {
		await steps[from].leave!()
	}

	if (to === -1) {
		return
	}

	if (!steps[to]) {
		await stopTour()
		return
	}

	if (steps[to]?.element === '.tickets-nav-item' && i18n.locale.value === 'pl') {
		step.value += 1
		return
	}

	if (steps[to]?.before) {
		try {
			await steps[to].before!()
		} catch (e) {
			console.warn(e)
		}
	}

	if (steps[to]?.route) {
		overlayClipPath.value = 'polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%)'
		await router.push(steps[to].route!)
		await new Promise(resolve => setTimeout(resolve, 1000))
	}

	const element: HTMLElement|null = document.querySelector(steps[to].element)

	if (!element) {
		step.value = -1
		return
	}

	await nextTick()

	updateClipPath()

	const top = element.getBoundingClientRect().top - 8
	const bottom = element.getBoundingClientRect().top + element.getBoundingClientRect().height + 8
	const left = element.getBoundingClientRect().left - 8
	const right = element.getBoundingClientRect().left + element.getBoundingClientRect().width + 8

	await nextTick()

	if (right < window.innerWidth - 400) {
		// enough space on the right
		if (top > window.innerHeight - 200) {
			// but not enough space below
			tourContentPosition.left = `${left}px`
			tourContentPosition.bottom = `${window.innerHeight - top + 8}px`
			tourContentPosition.top = 'auto'
			arrowPlacement.value = 'bottom-left'
		} else {
			tourContentPosition.left = `${right + 8}px`
			tourContentPosition.top = `${top}px`
			tourContentPosition.bottom = 'auto'
			arrowPlacement.value = 'left-top'
		}

		tourContentPosition.right = 'auto'
	} else {
		if (bottom > window.innerHeight - 200) {
			// not enough space below
			tourContentPosition.bottom = `${window.innerHeight - top + 8}px`
			tourContentPosition.top = 'auto'
			arrowPlacement.value = 'bottom-left'
		} else {
			tourContentPosition.top = `${bottom + 8}px`
			tourContentPosition.bottom = 'auto'
			arrowPlacement.value = 'top-right'
		}

		tourContentPosition.right = `${window.innerWidth - right}px`
		tourContentPosition.left = 'auto'
	}

	setTimeout(() => {
		showTourContent.value = true

		/*setTimeout(() => {
			if (steps[step.value].click) {
				document.querySelector<HTMLElement>(steps[step.value].click!)?.click()
				setTimeout(() => updateClipPath(element), 1000)
			}
		}, 2500)*/
	}, 750)
})

onMounted(() => {
	setTimeout(() => {
		step.value = 0
	}, 1000)

	window.addEventListener('resize', updateClipPath)
})

onBeforeUnmount(() => {
	window.removeEventListener('resize', updateClipPath)
})
</script>

<style lang="scss">
#tour-overlay {
	position: fixed; top: 0; left: 0; right: 0; bottom: 0;
	background: rgba(0, 0, 0, 0.5);
	clip-path: polygon(0% 0%, 0% 100%, 0 100%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%, 100% 100%, 100% 0%);
	// backdrop-filter: blur(0.1rem);
	transition: clip-path 0.3s;
	z-index: 5000;
}

#tour-overlay-transparent {
	position: fixed; top: 0; left: 0; right: 0; bottom: 0;
	background: transparent;
	z-index: 4999;
}

#tour-content {
	position: fixed;
	background-color: #fff;
	padding: 1rem;
	max-width: 400px;
	z-index: 5100;

	&::after {
		content: '';
		position: absolute;
		border-width: 6px;
		border-style: solid;
	}

	&.top-left-arrow::after {
		bottom: 100%;
		left: 20px;
		border-color: transparent transparent #fff transparent;
	}

	&.top-right-arrow::after {
		bottom: 100%;
		right: 20px;
		border-color: transparent transparent #fff transparent;
	}

	&.left-top-arrow::after {
		top: 20%;
		right: 100%;
		border-color: transparent #fff transparent transparent;
	}

	&.left-bottom-arrow::after {
		bottom: 20%;
		right: 100%;
		border-color: transparent #fff transparent transparent;
	}

	&.bottom-left-arrow::after {
		top: 100%;
		left: 20px;
		border-color: #fff transparent transparent transparent;
	}

	&.bottom-right-arrow::after {
		top: 100%;
		right: 20px;
		border-color: #fff transparent transparent transparent;
	}

	&.right-top-arrow::after {
		top: 20%;
		left: 100%;
		border-color: transparent transparent transparent #fff;
	}

	&.right-bottom-arrow::after {
		bottom: 20%;
		left: 100%;
		border-color: transparent transparent transparent #fff;
	}
}
</style>
